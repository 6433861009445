export const profDecoData = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Professional',
    description1:
      'Grand World Engineering Limited is a licensed electrical contracting company specializing in electrical engineering design, installation, testing, commissioning, maintenance and project management of electrical systems, including high-voltage works, since 2012. The company is certified ISO9001:2015; ISO14001:2015 and ISO45001:2018 and is also a certified body of Integrated Management System in “design, supply and installation of electrical systems including high voltage works as defined in Electrical Ordinance Chapter 406”.',
    imgStart: 'start',
    img1: 'images/iso 9001.png',
    img2: 'images/iso14001.png',
    img3: 'images/iso45001.png',
    img4: 'images/electricity-reg-cert.png'
  };
  
  export const profIMS1Data = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Integrated Management System',
    headline: ' ',
    description1:
      'Grand World Engineering Limited have developed and implemented Integrated Management System to consistently provide services to address client satisfaction and to meet applicable regulatory / statutory requirements through the effective application of the system, which includes continual improvement and prevention of the cause of potential nonconformity.',
    subdesc1:
      'The Integrated Management System complies with the international standard ISO 9001:2015, ISO 14001:2015 and OHSAS 18001:2007.',
    description2:
      'Comply with Integrated Management System, following manual and plans will be implemented to improve our services to satisfy clients needs:',
    imgStart: 'start'
  };

  export const profIMS2Data = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine1: 'Quality Manual',
    topLine2: 'Project Quality Plan',
    topLine3: 'Safety Health and Environment Plan',
    topLine4: 'Risk Management Plan',
    headline: ' ',
    description1:
      'The object of quality manual is to define authorities and responsibilities of the management personnel involved in the operation of the system, and to provide general procedures for all activities comprising the IMS. It also to present to our clients and other external interested parties, and to inform them of the specific controls that have been implemented within the company to assure quality.',
    description2:
      'These is prepared to demonstrate the ability and strategy of our company in the effective execution of this Electrical Solution of substation Project which include planning process; the improvement process and quality control process.',
    description3:
      'The company commits to continually improve its environmental performance and, ultimately, to minimize and prevent any environmental impacts of its operations, activities, products, and services.',
    description4:
      'To ensure that all risks are fully understood and that the project team makes risk-aware decision throughout the project. This plan provides guidelines for members of our company in accordance with the Quality Manual.',
    imgStart: 'start'
  };