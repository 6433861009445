import React from 'react';
import './ProfIMS1.css';

function ProfIMS1({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description1,
  subdesc1,
  description2,
  imgStart
}) {
  return (
    <>
      <div
        className={lightBg ? 'profims1-section' : 'profims1-section darkBg'}
      >
        <div className='container'>
            <div
              className='row profims1-row'
              style={{
                display: 'flex',
                flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
              }}
            >
              <div className='col-prof-text'>
                <div className='profims1-text-wrapper'>
                    <div className='profims1-top-line'>{topLine}</div>
                    <h1 className={lightText ? 'heading' : 'heading dark'}>
                        {headline}
                    </h1>
                    <p
                        className={
                        lightTextDesc
                            ? 'profims1-subtitle'
                            : 'profims1-subtitle dark'
                        }
                    >
                        {description1}
                    </p>
                    <p
                        className={
                        lightTextDesc
                            ? 'profims1-subtitle'
                            : 'profims1-subtitle dark'
                        }
                    >
                        {subdesc1}
                    </p>                    
                </div>
              </div>
            </div>
            <div
              className='row profims1-row'
              style={{
                display: 'flex',
                flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
              }}
            >
                <div className='col-prof-text'>
                    <div className='profims1-text-wrapper'>
                        <p
                            className={
                            lightTextDesc
                                ? 'profims1-subtitle'
                                : 'profims1-subtitle dark'
                            }
                        >
                            {description2}
                            <br/>
                            <div className='profims1-point'>
                                <ul>
                                    <li>Quality Manual </li>
                                    <li>Project Quality Plan </li>
                                    <li>Safety Health and Environment Plan </li>
                                    <li>Risk Management Plan </li>
                                </ul>
                            </div> 
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default ProfIMS1;