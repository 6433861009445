export const jobDecoData1 = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Our Jobs',
    description1: '',
    img1: 'images/CEM-OG.png',
    img2: 'images/CEM-DP.png',
    img3: 'images/CEM-HC.png',
    img4: 'images/CEM-LT.png',
    imgStart: 'start'
  };

  export const jobDecoData2 = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: '',
    description1: '',
    img1: 'images/CEM-CDP.png',
    img2: 'images/Parsian.png',
    img3: 'images/ThemePark.png',
    img4: 'images/SJM.png',
    imgStart: 'start'
  };