export const ServiceObj = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Services',
  description1:
    'Design, Engineering, Installation, Maintenance, Testing and commissioning of High Voltage equipment and substations involving 11kV to 66kV, 110kV, 220kV, Gas/Air Insulation Switch Vacuum Circuit Breakers, Power Transformers, Shunt Reactor and Capacitor banks for power utilities at Hong Kong and Macau substation.',
  description2:
    'Refurbishment of 400kV transformers and 132kV GIS switchgears, RMU and Reactors.',
  description3:
    'Design, Engineering, Installation, Testing & commissioning of electrical and building services installation for commercial/ industrial premises.',
  description4:
    'Design, Installation, Testing & commissioning of building controls and monitoring system.',
  description5:
    'Carry out preventive maintenance services on electrical equipment for local utilities, commercial and industrial premises.',
  description6:
    'Provide trenching cable laying supervision and cable jointing services up to and including 11kV, 22kV, 33kV, 66kV, 110kV, 132kV, 220kV cables for power utilities.',
  description7:
    'Provide Protection Relay, Digital Control System Installation and Commissioning.',
  imgStart: ''
};
