import React from 'react';
import ClientsDecor from './ClientsDecor';
import { dataObj } from './Data';

export default function Clients() {
  return (
    <>
      <ClientsDecor {...dataObj} />
    </>
  );
}