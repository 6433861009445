import React from 'react';
import './ClientsDecor.css';

function ClientsDecor({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  imgStart
}) {
  return (
    <>
      <div
        className={lightBg ? 'clients-section' : 'clients-section darkBg'}
      >
        <div className='container'>
          <div
            className='row clients-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='text-col'>
                <div className='clients-text-wrapper'>
                    <h1 className={lightText ? 'heading' : 'heading dark'}>
                    {headline}
                    </h1>
                    <div className='client-top-line'>{topLine}</div>
                </div>
            </div>
          </div>
          <div
            className='row clients-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
                <div className='clients-img-col'>
                    <div className='clients-img-wrapper'>
                        <img src={img1} alt='' className='clients-img' />
                    </div>
                    <div className='clients-img-wrapper'>
                        <img src={img2} alt='' className='clients-img' />
                    </div>
                    <div className='clients-img-wrapper'>
                        <img src={img3} alt='' className='clients-img' />
                    </div>
                    <div className='clients-img-wrapper'>
                        <img src={img4} alt='' className='clients-img' />
                    </div>
                    <div className='clients-img-wrapper'>
                        <img src={img5} alt='' className='clients-img' />
                    </div>
                    <div className='clients-img-wrapper'>
                        <img src={img6} alt='' className='clients-img' />
                    </div>
                </div>
                <div className='clients-img-col'>
                    <div className='clients-img-wrapper'>
                        <img src={img7} alt='' className='clients-img' />
                    </div>
                    <div className='clients-img-wrapper'>
                        <img src={img8} alt='' className='clients-img' />
                    </div>
                    <div className='clients-img-wrapper'>
                        <img src={img9} alt='' className='clients-img' />
                    </div>
                    <div className='clients-img-wrapper'>
                        <img src={img10} alt='' className='clients-img' />
                    </div>
                    <div className='clients-img-wrapper'>
                        <img src={img11} alt='' className='clients-img' />
                    </div>
                </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default ClientsDecor;
