import React from 'react';
import './JobDecorate.css';

function JobDecorate({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description1,
  img1,
  img2,
  img3,
  img4,
  imgStart
}) {
  return (
    <>
      <div
        className={lightBg ? 'job-section' : 'job-section darkBg'}
      >
        <div className='container'>
          <div
            className='row job-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='col-job-text'>
              <div className='job-text-wrapper'>
                    <div className='top-line'>{topLine}</div>
                    <h1 className={lightText ? 'heading' : 'heading dark'}>
                        {headline}
                    </h1>
              </div>
            </div>
          </div>           
            <div
                className='row job-row'
                style={{
                display: 'flex',
                flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
                }}
            >
                <div className='col-job-img'>
                    <div className='job-img-wrapper'>
                        <img src={img1} alt=' ' className='job-img ' />
                    </div>
                </div>
                <div className='col-job-img'>
                    <div className='job-img-wrapper'>
                        <img src={img2} alt=' ' className='job-img ' />
                    </div>
                </div>
            </div>                
            <div
                className='row job-row'
                style={{
                display: 'flex',
                flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
                }}
            >
                <div className='col-job-img'>
                    <div className='job-img-wrapper'>
                        <img src={img3} alt=' ' className='job-img ' />
                    </div>
                </div>
                <div className='col-job-img'>
                    <div className='job-img-wrapper'>
                        <img src={img4} alt=' ' className='job-img ' />
                    </div>
                </div>
            </div>        
          </div>
      </div>
    </>
  );
}

export default  JobDecorate;