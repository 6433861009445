import React from 'react';
import './ServiceDecorate.css';

function Service({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description1,
  description2,
  description3,
  description4,
  description5,
  description6,
  description7,
  imgStart
}) {
  return (
    <>
      <div
        className={lightBg ? 'service-section' : 'service-section darkBg'}
      >
        <div className='container'>
            <div
              className='row service-row'
              style={{
                display: 'flex',
                flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
              }}
            >
              <div className='col-serv-text'>
                <div className='service-text-wrapper'>
                    <div className='service-top-line'>{topLine}</div>
                    <h1 className={lightText ? 'heading' : 'heading dark'}>
                        {headline}
                    </h1>
                    <p
                        className={
                        lightTextDesc
                            ? 'service-subtitle'
                            : 'service-subtitle dark'
                        }
                    >
                        <ul><li>{description1}</li></ul>
                    </p>
                    <p
                        className={
                        lightTextDesc
                            ? 'service-subtitle'
                            : 'service-subtitle dark'
                        }
                    >
                        <ul><li>{description2}</li></ul>
                    </p>                    
                    <p
                        className={
                        lightTextDesc
                            ? 'service-subtitle'
                            : 'service-subtitle dark'
                        }
                    >
                        <ul><li>{description3}</li></ul>
                    </p>                    
                    <p
                        className={
                        lightTextDesc
                            ? 'service-subtitle'
                            : 'service-subtitle dark'
                        }
                    >
                        <ul><li>{description4}</li></ul>
                    </p>                    
                    <p
                        className={
                        lightTextDesc
                            ? 'service-subtitle'
                            : 'service-subtitle dark'
                        }
                    >
                        <ul><li>{description5}</li></ul>
                    </p>                    
                    <p
                        className={
                        lightTextDesc
                            ? 'service-subtitle'
                            : 'service-subtitle dark'
                        }
                    >
                        <ul><li>{description6}</li></ul>
                    </p>                    
                    <p
                        className={
                        lightTextDesc
                            ? 'service-subtitle'
                            : 'service-subtitle dark'
                        }
                    >
                        <ul><li>{description7}</li></ul>
                    </p>                    
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default Service;