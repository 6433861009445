import React from 'react';
import './ProfIMS2.css';

function ProfIMS2({
  lightBg,
  topLine1,
  topLine2,
  topLine3,
  topLine4,
  lightText,
  lightTextDesc,
  headline,
  description1,
  description2,
  description3,
  description4,
  imgStart
}) {
  return (
    <>
      <div
        className={lightBg ? 'profims2-section' : 'profims2-section darkBg'}
      >
        <div className='container'>
            <div
              className='row profims2-row'
              style={{
                display: 'flex',
                flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
              }}
            >
                <div className='col-profims2-text'>
                    <div className='profims2-text-wrapper'>
                        <div className='profims2-top-line'>{topLine2}</div>
                        <h1 className={lightText ? 'heading' : 'heading dark'}>
                            {headline}
                        </h1>
                        <p
                            className={
                            lightTextDesc
                                ? 'profims2-subtitle'
                                : 'profims2-subtitle dark'
                            }
                        >
                            {description2}
                        </p>
                    </div>
                </div>
                <div className='col-profims2-text'>
                    <div className='profims2-text-wrapper'>
                        <div className='profims2-top-line'>{topLine1}</div>
                        <h1 className={lightText ? 'heading' : 'heading dark'}>
                            {headline}
                        </h1>
                        <p
                            className={
                            lightTextDesc
                                ? 'profims2-subtitle'
                                : 'profims2-subtitle dark'
                            }
                        >
                            {description1}
                        </p>
                    </div>
                </div>                
            </div>
            <div
              className='row profims2-row'
              style={{
                display: 'flex',
                flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
              }}
            >
                <div className='col-profims2-text'>
                    <div className='profims2-text-wrapper'>
                        <div className='profims2-top-line'>{topLine4}</div>
                        <h1 className={lightText ? 'heading' : 'heading dark'}>
                            {headline}
                        </h1>
                        <p
                            className={
                            lightTextDesc
                                ? 'profims2-subtitle'
                                : 'profims2-subtitle dark'
                            }
                        >
                            {description4}
                        </p>
                    </div>
                </div>           
                <div className='col-profims2-text'>
                    <div className='profims2-text-wrapper'>
                        <div className='profims2-top-line'>{topLine3}</div>
                        <h1 className={lightText ? 'heading' : 'heading dark'}>
                            {headline}
                        </h1>
                        <p
                            className={
                            lightTextDesc
                                ? 'profims2-subtitle'
                                : 'profims2-subtitle dark'
                            }
                        >
                            {description3}
                        </p>
                    </div>
                </div>                           
            </div>
        </div>
      </div>
    </>
  );
}

export default ProfIMS2;