import React from 'react';
import ProfDecorate from './ProfDecorate';
import ProfIMS1 from './ProfIMS1';
import ProfIMS2 from './ProfIMS2';
import { profDecoData, profIMS1Data, profIMS2Data } from './Data';

export default function Professional() {
  return (
    <>
      <ProfDecorate {...profDecoData} />
      <ProfIMS1 {...profIMS1Data} />
      <ProfIMS2 {...profIMS2Data} />
    </>
  );
}