import React from 'react';
import './ProfDecorate.css';

function ProfDecorate({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  description1,
  img1,
  img2,
  img3,
  img4,
  imgStart
}) {
  return (
    <>
      <div
        className={lightBg ? 'professional-section' : 'professional-section darkBg'}
      >
        <div className='container'>
          <div
            className='row professional-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='col-prof-text'>
              <div className='professional-text-wrapper'>
                  <div className='top-line'>{topLine}</div>
                  <h1 className={lightText ? 'heading' : 'heading dark'}>
                      {headline}
                  </h1>
                  <p
                      className={
                      lightTextDesc
                          ? 'professional-subtitle'
                          : 'professional-subtitle dark'
                      }
                  >
                      {description1}
                  </p>
              </div>
            </div>
          </div>
          <div
            className='row professional-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
              <div className='col-prof-img'>
                  <div className='professional-img-wrapper'>
                      <img src={img1} alt=' ' className='professional-img ' />
                  </div>
              </div>
              <div className='col-prof-img'>
                  <div className='professional-img-wrapper'>
                      <img src={img2} alt=' ' className='professional-img ' />
                  </div>
              </div>
              <div className='col-prof-img'>
                  <div className='professional-img-wrapper'>
                      <img src={img3} alt=' ' className='professional-img ' />
                  </div>
              </div>
              <div className='col-prof-img'>
                  <div className='professional-img-wrapper'>
                      <img src={img4} alt=' ' className='professional-img ' />
                  </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfDecorate;