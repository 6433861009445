import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './components/pages/Home/Home';
import Aboutus from './components/pages/Aboutus/Aboutus'
import Services from './components/pages/Services/Services';
import Clients from './components/pages/Clients/Clients';
import Professional from './components/pages/Professional/Professional';
import Jobs from './components/pages/Jobs/Jobs';

// import Products from './components/pages/Products/Products';
// import Consulting from './components/pages/Consulting/Consulting';

import Navbar from './components/Navbar';
import Footer from './components/pages/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/aboutus' component={Aboutus} />
        <Route path='/services' component={Services} />
        <Route path='/clients' component={Clients} />
        <Route path='/professional' component={Professional} />
        <Route path='/jobs' component={Jobs} />

        {/* <Route path='/products' component={Products} />
        <Route path='/consulting' component={Consulting} /> */}
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
