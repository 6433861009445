export const dataObj = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Government & industrial buildings with the following clients:',
    headline: 'Clients',
    description:
      '',
    imgStart: '',
    img1: 'images/CLP.png',
    img2: 'images/HKE.png',
    img3: 'images/SMS.png',
    img4: 'images/SEHK.png',
    img5: 'images/GE.png',
    img6: 'images/WYNN.png',
    img7: 'images/CEM.png',
    img8: 'images/MIA.png',
    img9: 'images/XIAN.png',
    img10: 'images/ABB.png',
    img11: 'images/LGL.png',
    alt: ''
  };