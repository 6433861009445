import React from 'react';
import ServiceDecorate from './ServiceDecorate';
import { ServiceObj } from './Data';

function Services() {
  return (
    <>
      <ServiceDecorate {...ServiceObj} />
    </>
  );
}

export default Services;
