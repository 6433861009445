import React from 'react';
import JobDecorate from './JobDecorate';
import { jobDecoData1, jobDecoData2 } from './Data';

export default function Jobs() {
  return (
    <>
      <JobDecorate {...jobDecoData1} />
      <JobDecorate {...jobDecoData2} />
    </>
  );
}