import React from 'react';
import Decorate from './Decorate';
import { homeObjOne, homeObjTwo } from './Data';

export default function Aboutus() {
  return (
    <>
      <Decorate {...homeObjOne} />
      <Decorate {...homeObjTwo} />
    </>
  );
}