export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'About Us',
    description1:
      'Grand World Engineering Limited is a licensed electrical contracting company specializing in electrical engineering design, installation, testing, commissioning, maintenance and project management of electrical systems, including high-voltage works, since 2012.',
    description2: 'Grand World Engineering is a company based in Hong Kong and is well known for the services, such as design, installation, testing and commissioning, for the high voltage electrical equipment including but not limited to GIS switchgear, transformers, reactors, capacitor bank, protection & control equipment for various voltage levels from 11kV up to 400kV system. This company is also well known to provide the services for the maintenance services for customer’s owned electrical equipment and substations. Since 2012, Grand World Engineering already extended their services and expertise to CLP and CEM in Macau and has established an office in Macau until now. In addition, Grand World Engineering can also supply material and equipment according to customer’s need.',
    imgStart: '',
    img: '',
    alt: ''
  };
  
  export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: '',
    description1:
      'With the expertise experience of our company, we aim at providing the best services to the installation of electrical substation and providing reliable electrical equipment for Hong Kong and Macau’s society. In order to ensure safety electrical supply to the society, our company also aims at providing reliable and schedule maintenance services to customer’s owned substation in Hong Kong and Macau. Provide better training to our engineers for the Design, Installation and Testing & Commissioning of electrical substations, in particular for the High Voltage Substations from 11kV up to 400kV Voltage levels. Enhance reliable electrical service to electrical equipment. ',
    description2: '',    
    imgStart: '',
    img: '',
    alt: ''
  };
  