import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Contact Us
        </p>
        <p className='footer-subscription-text'>
        Address: Unit 3, 12/F, EW International Tower, No.120 Texaco Road, Tsuen Wan, N.T., H.K.
        </p>
        <p className='footer-subscription-text'>
        Tel: +852 3622 3968 / +852 3622 3989
        </p>
        <p className='footer-subscription-text'>
        Fax: +852 3622 3967
        </p>
        <p className='footer-subscription-text'>
        Email: grandworld@gwel.com.hk
        </p>
      </section>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
          <Link to='/' className='social-logo'>
              <span className='gwel-footer-icon' />
              <span className='gwel-name'>GWEL</span>
          </Link>
          </div>
          <small className='website-rights'>Grand World Engineering Limited © 2021</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
